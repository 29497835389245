<template>
  <div class="card card-custom card-transparent w-100">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  1
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Λογαριασμός
                  </div>
                  <div class="wizard-desc">
                    Λεπτομέριες <br />
                    Λογαριασού
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  2
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Διεύθυνση
                  </div>
                  <div class="wizard-desc">
                    Στοιχεία <br />
                    διεύθυνσης
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  3
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Ολοκρήρωση
                  </div>
                  <div class="wizard-desc">
                    Aναθεώρηση και <br />
                    υποβολή
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form
                  class="form mt-0 mt-lg-10"
                  novalidate="novalidate"
                  id="kt_form"
                >
                  <!--begin: Wizard Step 1-->
                  <div
                    id="wizardStepOne"
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div class="mb-10 font-weight-bold text-dark">
                      <h3
                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                      >
                        Δημιουργία λογαριασμου
                      </h3>
                      <span class="text-muted font-weight-bold font-size-h4"
                        >Έχεις ήδη λογαριασμό;
                        <router-link
                          class="text-primary font-weight-bolder"
                          :to="{ name: 'login' }"
                        >
                          Συνδέσου
                        </router-link>
                      </span>
                    </div>
                    <div class="form-group">
                      <label>Ονοματεπώνυμο</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="fullName"
                        placeholder="oνοματεπώνυμο"
                        ref="rfullName"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control form-control-solid form-control-lg"
                        name="email"
                        placeholder="email"
                        ref="remail"
                      />
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Κωδικός</label>
                          <input
                            type="password"
                            class="form-control form-control-solid form-control-lg"
                            name="password"
                            placeholder="κωδικός"
                            ref="rpassword"
                          />
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Επιβεβαίωση κωδικού</label>
                          <input
                            type="password"
                            class="form-control form-control-solid form-control-lg"
                            name="cpassword"
                            placeholder="επιβεβαίωση κωδικού"
                            ref="cpassword"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Κινητό τηλέφωνο</label>
                      <input
                        type="tel"
                        class="form-control form-control-solid form-control-lg"
                        name="phone"
                        placeholder="κινητό τηλέφωνο"
                        ref="rphone"
                      />
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div
                    id="wizardStepTwo"
                    class="pb-5"
                    data-wizard-type="step-content"
                  >
                    <div class="mb-10 font-weight-bold text-dark">
                      Setup Your Address
                    </div>
                    <div class="form-group">
                      <label>Διεύθυνση</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="address"
                        placeholder="διεύθυνση"
                        ref="raddress"
                      />
                    </div>
                    <div class="form-group">
                      <label>TEE</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="tee"
                        placeholder="TEE"
                        ref="rtee"
                      />
                    </div>
                    <div class="form-group">
                      <label>Πόλη</label>
                      <select
                        name="city"
                        class="form-control form-control-solid form-control-lg"
                        ref="rcity"
                      >
                        <option value="">επίλεξε πόλη</option>
                        <option value="1">Αθήνα</option>
                        <option value="2">Θεσσαλονίκη</option>
                        <option value="3">Λάρισα</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Νομός</label>
                      <select
                        name="state"
                        class="form-control form-control-solid form-control-lg"
                        ref="rstate"
                      >
                        <option value="">επίλεξε νομό</option>
                        <option value="1">Αττική</option>
                        <option value="2">Θεσσαλονίκη</option>
                        <option value="3">Λάρισα</option>
                      </select>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div
                    id="wizardStepThree"
                    class="pb-5"
                    data-wizard-type="step-content"
                  >
                    <div class="mb-10 font-weight-bold text-dark">
                      Review your Details and Submit
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        Your Account Details:
                      </div>
                      <div class="line-height-md" id="accountDetails"></div>
                    </div>
                    <div class="mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        Your Address Details:
                      </div>
                      <div class="line-height-md" id="addressDetails"></div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        ΠΡΟΗΓΟΥΜΕΝΟ
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                        id="formSubmitBtn"
                      >
                        ΥΠΟΒΟΛΗ
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        ΕΠΟΜΕΝΟ
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-custom.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { LOGOUT, REGISTER } from "@/core/services/store/auth.module";

export default {
  name: "register",
  mounted() {
    const main = this;
    main.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // form element
    const form = KTUtil.getById("kt_form");

    // step 1 inputs container element
    const wizardStepOne = KTUtil.getById("wizardStepOne");

    // step 2 inputs container element
    const wizardStepTwo = KTUtil.getById("wizardStepTwo");

    // step 3 account details reevaluation container
    const accountDetailsContainer = KTUtil.getById("accountDetails");

    // step 3 address details reevaluation container
    const addressDetailsContainer = KTUtil.getById("addressDetails");

    // All the input elements of the registration page
    const formInputs = {
      fullName: main.$refs.rfullName,
      email: main.$refs.remail,
      password: main.$refs.rpassword,
      phone: main.$refs.rphone,
      address: main.$refs.raddress,
      tee: main.$refs.rtee,
      city: main.$refs.rcity,
      state: main.$refs.rstate
    };

    // step 1 validation rules
    const stepOneValidationFields = {
      fullName: {
        validators: {
          notEmpty: {
            message: "Το Ονοματεπώνυμο είναι απαραίτητο"
          },
          regexp: {
            regexp: /^[a-zA-Zα-ωΑ-Ωά-ώΆ-Ώ\s]+$/i,
            message:
              "Το Ονοματεπώνυμο μπορεί να περιέχει μόνο γράμματα και κενά"
          }
        }
      },
      email: {
        validators: {
          notEmpty: {
            message: "Το email είναι απαραίτητο"
          },
          emailAddress: {
            message: "Το email δεν έχει σωστή μορφή"
          }
        }
      },
      password: {
        validators: {
          notEmpty: {
            message: "Ο κωδικός είναι απαραίτητος"
          },
          regexp: {
            regexp: /(?=.*\d)(?=.*\p{Ll})(?=.*\p{Lu})/u,
            message:
              "Ο κωδικός πρέπει να περιέχει τουλάχιστον ένα μικρό κι ένα κεφαλαίο γράμμα και τουλάχιστον έναν αριθμό."
          },
          stringLength: {
            min: 8,
            max: 16,
            message: "Ο κωδικός πρέπει να είναι από 8 έως 16 χαρακτήρες"
          }
        }
      },
      cpassword: {
        validators: {
          notEmpty: {
            message: "Ο κωδικός επιβεβαίωσης είναι απαραίτητος"
          },
          identical: {
            compare: function() {
              return form.querySelector('[name="password"]').value;
            },
            message: "Οι κωδικοί δεν ταιριάζουν"
          }
        }
      },
      phone: {
        validators: {
          notEmpty: {
            message: "Το κινητό είναι απαραίτητο"
          },
          integer: {
            message: "Το κινητό πρέπει να είναι της μορφής 69xxxxxxxx"
          },
          between: {
            min: 6900000000,
            max: 6999999999,
            message: "Το κινητό πρέπει να έχει 10 ψηφία και να ξεκινάει από 69"
          }
        }
      }
    };

    // step 2 validation rules
    const stepTwoValidationFields = {
      address: {
        validators: {
          notEmpty: {
            message: "Η διεύθυνση είναι υποχρεωτική"
          },
          regexp: {
            regexp: /^[a-zA-Z0-9α-ωΑ-Ωά-ώΆ-Ώ\s]+$/i,
            message:
              "Η διεύθυνση μπορεί να περιέχει μονο γράμματα, νούμερα και κενά"
          }
        }
      },
      tee: {
        validators: {
          notEmpty: {
            message: "Ο αριθμός ΤΕΕ είναι υποχρεωτικός"
          },
          digits: {
            message: "Ο αριθμός ΤΕΕ πρέπει να περιέχει μονο αριθμούς"
          }
        }
      },
      city: {
        validators: {
          notEmpty: {
            message: "Παρακαλώ επιλέξτε μια πόλη"
          }
        }
      },
      state: {
        validators: {
          notEmpty: {
            message: "Παρακαλώ επιλέξτε έναν νομό"
          }
        }
      }
    };

    // step 1 validation init
    main.fv1 = formValidation(wizardStepOne, {
      fields: stepOneValidationFields,
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });

    // step 2 validation init
    main.fv2 = formValidation(wizardStepTwo, {
      fields: stepTwoValidationFields,
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });

    // step 3 full re-validation init
    main.fv = formValidation(form, {
      fields: {
        ...stepOneValidationFields,
        ...stepTwoValidationFields
      },
      plugins: {
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    // form successful re-validation and submit
    main.fv.on("core.form.valid", () => {
      // clear existing errors
      this.$store
        .dispatch(LOGOUT)
        .then(() => {})
        .catch(() => {});

      // set spinner to submit button
      const submitBtn = KTUtil.getById("formSubmitBtn");
      submitBtn.classList.add("spinner", "spinner-light", "spinner-left");

      // Register payload
      const registerPayload = {
        fullName: formInputs.fullName.value,
        email: formInputs.email.value,
        password: formInputs.password.value,
        mobile: "30" + formInputs.phone.value,
        address: formInputs.address.value,
        tee: formInputs.tee.value,
        cityId: formInputs.city.value,
        prefectureId: formInputs.state.value
      };

      // Register request
      this.$store
        .dispatch(REGISTER, registerPayload)
        .then(() => {
          main.$router.push("login");
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!", //TODO: set resp.data.message
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        })
        .finally(() => {
          submitBtn.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    // form failed re-validation
    main.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    // Run validation before going to next page
    wizard.on("change", function(wizardObj) {
      const step = wizardObj.getStep();
      const nextStep = wizardObj.getNewStep();

      if (nextStep > step) {
        wizardObj.stop();

        switch (step) {
          case 1:
            main.fv1.validate().then(function(status) {
              if (status === "Valid") {
                addDataToElement(accountDetailsContainer, {
                  "Full Name": formInputs.fullName.value,
                  Email: formInputs.email.value,
                  Phone: formInputs.phone.value
                });
                wizardObj.goNext();
              }
            });
            break;
          case 2:
            main.fv2.validate().then(function(status) {
              if (status === "Valid") {
                addDataToElement(addressDetailsContainer, {
                  Address: formInputs.address.value,
                  TEE: formInputs.tee.value,
                  City: formInputs.city.value,
                  State: formInputs.state.value
                });
                wizardObj.goNext();
              }
            });
            break;
        }
      }
    });

    // Scroll on top when changing step
    wizard.on("changed", function(/*wizardObj*/) {
      KTUtil.scrollTop();
    });

    // function to populate element with reevaluation data
    function addDataToElement(element, data) {
      element.innerHTML = "";

      Object.keys(data).forEach(key => {
        const strong = document.createElement("strong");
        strong.innerHTML = key + ": ";

        element.append(strong);
        element.append(data[key]);
        element.append(document.createElement("br"));
      });
    }
  },
  methods: {}
};
</script>
